import Container from "./Container.jsx";

export default function About({ children }) {
  return (
    <div id="about">
      <Container>
        <div className="pt-24 mt -12 mb-24 space-y-2 text-center">
          {/* <h2 className="mb-6 text-center text-4xl font-bold text-white dark:text-white md:text-5xl"></h2> */}
          <p className="lg:mx-auto text-xl lg:w-6/12 text-white dark:text-gray-300">
            Fractal - Manipulation of the Physical
          </p>
        </div>
        <div className="grid gap-8 md:grid lg:grid">
          <div className="">
            <div className="relative overflow-hidden rounded-xl">
            </div>
          </div>
          <div className="p-6 sm:p-8 rounded-3xl bg-transparent border border-primary dark:border-gray-700 shadow-2xl shadow-gray-600/50">
            <div className="relative overflow-hidden rounded-xl">
              <video
                type="video/mp4"
                alt="art cover"
                width="1000"
                height="667"
                autoPlay
                muted
                loop
                playsInline
                className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
              >
                <source src="./videos/fractal_eye.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {/* alt="art cover" loading="lazy" width="1000" height="667" className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105" /> */}
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white text-center">
                Fractal Roadmap
              </h3>
            </div>
            <div className="mt-6 relative">
              <h3 className="text-2xl font-semibold text-white dark:text-white text-center">
                Future I
              </h3>
              {/* <a className="" href="/"> */}
              <span className="text-white dark:text-blue-300 text-center">
                <div className="mt-6 mb-8 text-white dark:text-gray-300 text-center">
                  <ul>
                    <li>Build 1000 NFT art collection</li>
                    <li>Build brand</li>
                    <li>Build community</li>
                    <li>Launch 1st collection on 11th November 2023</li>
                    <li>Holders receive free mints & airdrops in other futures</li>
                  </ul>
                  <div className="mt-6 relative">
                    <h3 className="text-2xl font-semibold text-white dark:text-white text-center">
                      Future II
                    </h3>
                    <div className="mt-6 mb-8 text-white dark:text-gray-300 text-center">
                      <ul>
                        <li>Build second 3000 NFT art collection</li>
                        <li>Give free mints to OG Fractal holders</li>
                        <li>Grow community with partnerships</li>
                      </ul>
                    </div>
                    <h3 className="text-2xl font-semibold text-white dark:text-white text-center">
                      Future III
                    </h3>
                    <div className="mt-6 mb-8 text-white dark:text-gray-300 text-center">
                      <ul>
                        <li>Build memecoin and airdrop it to all holders</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
              {/* </a> */}
            </div>
          </div>
          <div className="relative overflow-hidden rounded-xl">
          </div>

        </div>
      </Container>
    </div>
  );
}