import { ConnectKitButton } from "connectkit";
import Container from "./Container.jsx";

const links = [
    {
        to: "/",
        label: "Home",
    },
    {
        to: "/roadmap",
        label: "Roadmap",
    },
    {
        to: "/backstory",
        label: "Backstory",
    },
    {
        to: "https://pulsemarket.app/collection/0xAEb8a891C8D9c61a7F4711927D17D68d118CF68a",
        label: "Marketplace",
    },
];

export default function AppHeader({ children }) {
    return (
        <header>
            <nav className="z-10 w-full border-b border-black/5 dark:border-white/5 lg:border-transparent">
                <Container>
                    <div className="relative flex flex-wrap items-center justify-between gap-6 py-3 md:gap-0 md:py-4">
                        <div className="relative z-20 flex w-full justify-between md:px-0 lg:w-max">

                            {/* Add a container for logos */}
                            <div className="flex items-center space-x-2">
                                <div aria-hidden="true" className="flex space-x-1">
                                    <img src="/fractal_logo.png" alt="Fractal Logo" className="w-10 h-10" />
                                </div>
                                <div aria-hidden="true" className="flex space-x-1">
                                    <img src="/fractal_xl.png" alt="Second Logo" className="w-18 h-10" />
                                </div>
                            </div>
                            {/* End of logos */}
                            <div className="relative flex max-h-10 items-center lg:hidden">
                                <button aria-label="humburger" id="hamburger" className="relative -mr-6 p-6">
                                    <div aria-hidden="true" id="line" className="m-auto h-1 w-7 bg-white transition-transform duration-300"></div>
                                    <div aria-hidden="true" id="line2" className="m-auto mt-1 h-1 w-7 bg-white transition-transform duration-300"></div>
                                    <div aria-hidden="true" id="line3" className="m-auto mt-1 h-1 w-7 bg-white transition-transform duration-300"></div>
                                </button>
                            </div>
                            {/* <span className="text-2xl font-bold text-white dark:text-white">Fractal</span> */}
                        </div>
                        <div id="navLayer" aria-hidden="true" className="fixed inset-0 z-10 h-screen w-screen origin-bottom scale-y-0 transition duration-500 dark:bg-gray-900/70 lg:hidden"></div>
                        <div id="navlinks" className="invisible absolute flex-nowrap top-full left-0 z-20 w-full origin-top-right translate-y-1 scale-90 flex-col justify-end gap-6 rounded-3xl border border-gray-100 bg-[#0c0920] p-8 opacity-0 shadow-2xl shadow-gray-600/10 transition-all duration-300 lg:visible lg:relative lg:flex lg:w-7/12 lg:translate-y-0 lg:scale-100 lg:flex-row lg:items-center lg:gap-0 lg:border-none lg:bg-transparent lg:p-0 lg:opacity-100 lg:shadow-none">
                            <div className="w-full text-white dark:text-gray-200 lg:w-auto lg:pr-4 lg:pt-0">
                                <ul className="flex flex-col gap-6 tracking-wide lg:flex-row lg:gap-0 lg:text-sm">
                                    {
                                        links.map((link, index) => (
                                            <li key={index}>
                                                <a href={link.to} className="link md:px-4">
                                                    {link.label}
                                                </a>
                                            </li>
                                        ))
                                    }
                                    <li>

                                    </li>
                                </ul>
                            </div>
                            <div className="mt-12 lg:mt-0 flex flex-row justify-start">
                                <div className="btn-connect-container">
                                    <ConnectKitButton />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </nav>
        </header>
    );
}
