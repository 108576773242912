import Container from "./Container.jsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';

export default function AppFooter({ children }) {
  return (
    <footer className="py-60 md:py-60">
      <Container>
        <div className="m-auto md:w-10/12 lg:w-8/12 xl:w-6/12">
          <div className="flex flex-wrap items-center justify-between md:flex-nowrap">
            <div className="flex w-full justify-center space-x-12 text-white dark:text-gray-300 sm:w-7/12 md:justify-start">
              <ul className="space-y-8">
                {/* <li>
                <a href="https://github.com/Hocus-Pocus-Finance/" className="flex items-center space-x-3 transition hover:text-primary">
                    <FontAwesomeIcon icon={faGithub} className="w-5" />
                    <span>Github</span>
                  </a>
                </li> */}
                <li>
                  <a href="https://twitter.com/FractalonPulse/" className="flex items-center space-x-3 transition hover:text-primary">
                    <FontAwesomeIcon icon={faXTwitter} className="w-5" />
                    <span>Twitter</span> 
                  </a>
                </li>
                <li>
                  <a href="https://t.me/FractalonPulse/" className="flex items-center space-x-3 transition hover:text-primary">
                    <FontAwesomeIcon icon={faTelegram} className="w-5" />
                    <span>Telegram</span>
                  </a>
                </li>
              </ul>
            </div>
            <div
              className="m-auto mt-16 w-10/12 space-y-6 text-center sm:mt-auto sm:w-5/12 sm:text-left"
            >
              <span className="block text-gray-500 dark:text-gray-400"
              >Copyright © 2023 - Fractal, all right reserved.</span
              >

              <span className="block text-gray-500 dark:text-gray-400">
    Supported by <a href="https://www.pulsemarket.app" className="text-gray-500 dark:text-gray-400 hover:underline">PulseMarket</a> &copy; 
</span>
              <span className="flex justify-between text-gray-600 dark:text-white">
                <a href="https://t.me/FractalonPulse/" className="font-medium">For feedback or support</a>
                <a href="https://t.me/FractalonPulse/" className="font-medium">Join our Telegram</a>
              </span>

              {/* <span className="block text-gray-500 dark:text-gray-400"
              >Need help? <a href="#contact" className="font-semibold text-gray-600 dark:text-white">Contact Us via X</a></span
              > */}
            </div>
          </div>
        </div>
      </Container>
    </footer >
  );
}
