import Container from "./Container.jsx";

export default function Backstory({ children }) {
  return (
    <div className="relative">
      <div aria-hidden="true" className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20">
        <div className="blur-[106px] h-56 bg-gradient-to-br from-primary to-purple-400 dark:from-blue-700"></div>
        <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400 to-sky-300 dark:to-indigo-600"></div>
      </div>
      <Container>
        <div className="relative">
          <h1 className="mt-24 text-center text-4xl font-bold text-white dark:text-white md:text-5xl">Fractal Backstory</h1>
          <div className="mt-24 mb-24 flex items-center justify-center -space-x-2">
            <video
              width="800"
              height="800"
              autoPlay
              muted
              loop
              playsInline
              className="h-72 w-72 rounded-full"
            >
              <source src="./videos/fractal_clip2.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="mt-6 m-auto space-y-6 md:w-8/12 lg:w-7/12">
            <p className="text-center text-xl text-white dark:text-gray-300">
              "Fractal - Manipulation of the Physical" is a groundbreaking NFT project that delves into the imaginative realms of a futuristic dystopian world, where artificial intelligence and advanced robotics have become an integral part of human existence. The NFT collection revolves around a captivating mythical narrative that seamlessly blends science fiction and fantasy elements, creating a truly unique and immersive experience for collectors.
            </p>
            <p className="text-center text-xl text-white dark:text-gray-300">
              In the not-so-distant future, humanity finds itself at a crossroads, as the convergence of technology and mythology sparks a new era. It is a time when AI and bots have revolutionized every aspect of life, from everyday routines to monumental undertakings. Yet, this world remains cloaked in a sense of eerie mysticism, where ancient legends and folklore intertwine with the cutting-edge innovations of a rapidly evolving society.</p>
            <div>
              <div className="relative">
                <div className="mt-24 mb-24 flex items-center justify-center -space-x-2">
                  {/* <img
              loading="lazy"
              width="400"
              height="400"
              src="./images/avatars/5.png"
              alt="sfractal"
              className="h-8 w-8 rounded-full object-cover"
            /> */}
                  <img
                    loading="lazy"
                    width="200"
                    height="200"
                    src="./images/avatars/55.png"
                    alt="fractal"
                    className="h-24 w-24 rounded-full object-cover"
                  />
                  <img
                    loading="lazy"
                    width="200"
                    height="200"
                    src="./images/avatars/115.png"
                    alt="fractal"
                    className="z-10 h-32 w-32 rounded-full object-cover"
                  />
                  <img
                    loading="lazy"
                    width="200"
                    height="200"
                    src="./images/avatars/307.png"
                    alt="fractal"
                    className="relative h-24 w-24 rounded-full object-cover"
                  />
                  {/* <img
              loading="lazy"
              width="200"
              height="200"
              src="./images/avatars/394.png"
              alt="fractal"
              className="h-8 w-8 rounded-full object-cover"
            /> */}
                </div>
              </div>
              <div className="mt-6 m-auto space-y-6">
                <p className="text-center text-xl text-white dark:text-gray-300">
                  The NFT collection features a diverse array of characters, each meticulously crafted by artificial intelligence, ensuring their unparalleled uniqueness. These characters belong to distinct factions, each with its own rich lore:</p>
                <p className="text-center text-xl text-white dark:text-gray-300">
                  Droids: Cold, mechanical beings that serve various functions in society, from labor to security. They represent the stark, utilitarian aspect of this future world.
                </p>
                <p className="text-center text-xl text-white dark:text-gray-300">
                  Bots: Agile and adaptable, bots are the workhorses of the futuristic society, performing intricate tasks and facilitating human life. They are the embodiment of efficiency and precision.
                </p>
                <p className="text-center text-xl text-white dark:text-gray-300">
                  Mythical: Enigmatic and mysterious, the Mythical faction comprises creatures and entities from the ancient folklore of this world. These beings possess powers and knowledge beyond the grasp of humans.
                </p>
                <p className="text-center text-xl text-white dark:text-gray-300">
                  Elemental: Tied to the very forces of nature, the Elemental faction harnesses the powers of earth, fire, water, and air, adding an elemental and ecological dimension to the NFT world.
                </p>
                <p className="text-center text-xl text-white dark:text-gray-300">
                  Legendary: The most enigmatic and revered faction, Legendary characters embody the extraordinary and extraordinary in this world. Their origins and purposes remain shrouded in myth and legend.</p>
                <p className="text-center text-xl text-white dark:text-gray-300">
                  "Fractal - Manipulation of the Physical" NFTs invite collectors to immerse themselves in a world where technology and myth collide, offering a visually stunning and intellectually engaging experience. With each character being a 1-of-1 NFT, these digital tokens capture the essence of this unique world, where the boundaries between reality and fantasy blur, and the possibilities are as limitless as the human imagination.</p>
                <div className="mt-6 flex flex-wrap justify-center gap-6">
                  <a
                    href="/"
                    className="relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-primary/10 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max"
                  >
                  </a>
                </div>
                <div>
                  <div className="relative">
                    <div className="mt-12 mb-24 flex items-center justify-center -space-x-2">
                      <video
                        width="800"
                        height="800"
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="h-72 w-72 rounded-full"
                      >
                        <source src="./videos/video1.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div className="mt-6 m-auto space-y-6">
                    <p className="text-center text-xl text-white dark:text-gray-300">
                      "Fractal - Manipulation of the Physical" takes its name from the concept of fractals, which plays a central role in the creation and essence of this NFT project. The world depicted in these NFTs is a fusion of technology, myth, and intricate fractal patterns, resulting in an experience that is both visually captivating and intellectually stimulating.</p>
                    <p className="text-center text-xl text-white dark:text-gray-300">
                      n this futuristic dystopian world, the characters are designed with a unique approach, drawing inspiration from fractals, which are infinitely complex patterns created with different scales. Just as fractals exhibit self-similarity at various levels, the characters in the collection are fractionally complete, each reflecting a small part of the larger narrative, yet embodying their own distinctive identities within their respective factions.
                    </p>
                    <p className="text-center text-xl text-white dark:text-gray-300">
                      The name "Fractal" also alludes to the interconnectedness of this world, where every character, every detail, and every storyline is intricately woven into the broader tapestry of the narrative. Each NFT represents a fractal piece of this multifaceted universe, offering collectors a glimpse into the infinite possibilities that exist within it.
                    </p>
                    <p className="text-center text-xl text-white dark:text-gray-300">
                      As collectors explore the NFTs in the Droids, Bots, Mythical, Elemental, and Legendary factions, they will discover how the concept of fractals not only influences the visual design but also the underlying philosophy of this project. Just as fractals continue to reveal new intricacies and beauty with each level of magnification, "Fractal - Manipulation of the Physical" invites its audience to uncover the hidden depths and complexities of this mesmerizing dystopian future, one character at a time.
                    </p>
                    <div className="mt-6 flex flex-wrap justify-center gap-6">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
