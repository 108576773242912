import { WagmiConfig, createConfig } from 'wagmi';
import { pulsechain } from 'wagmi/chains';
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';

import  Roadmap  from './components/Roadmap';
import Backstory from './components/Backstory';
import HeroSection from './components/HeroSection';
import Layout from './components/Layout';

const wagmiConfig = createConfig(
  getDefaultConfig({
    // Required API Keys
    // alchemyId: process.env.ALCHEMY_ID, // or infuraId
    walletConnectProjectId: '8c594cc42ab8432db3262ca48f4e815b',

    // Required
    appName: "Fractals",
    // Optional
    appDescription: "Fractals DApp",
    appUrl: "https://FractalsonPulse.com",
    appIcon: "",
    chains: [pulsechain],
  }),
);

function Home() {
  return <>
    <HeroSection />
    {/* <About/> */}
    {/* <CallToAction /> */}
  </>;
}

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <ConnectKitProvider
        theme="midnight"
        mode="dark"
        customTheme={{
          "--ck-font-family": "Orbitron, sans-serif",
          "--ck-connectbutton-border-radius": "30px",
          "--ck-connectbutton-border": "#06aaeb", 
          "--ck-connectbutton-color": "#ffffff",
          "--ck-connectbutton-background": "transparent",
          "--ck-connectbutton-hover-background": "#06aaeb",
          "--ck-connectbutton-padding": "12px 24px", // padding to make the button bigger
          "--ck-connectbutton-font-size": "0.9rem", // fontsize
        }}
      >
        <Layout title="Welcome to Fractals">
          <main>
            <BrowserRouter>
              <Routes>
                <Route path="/">
                  <Route index element={<Home />} />
                  <Route path="roadmap" element={<Roadmap />} />
                  <Route path="backstory" element={<Backstory />} />
                </Route>
              </Routes>
              {/* <Claim /> */}
            </BrowserRouter>
          </main>
        </Layout>
      </ConnectKitProvider>
    </WagmiConfig>
  );
}

export default App;
